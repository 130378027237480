<template>
  <v-container fluid>
    <v-row
      class="mx-auto"
      v-for="category in categories"
      :key="category.name"
    >
      <v-col cols="12">
        <h2>{{ category.name }}</h2>
      </v-col>
      <v-col
        xs="12"
        sm="6"
        md="4"
        xl="3"
        v-for="(item, _key) in category.itens"
        :key="_key"
      >
        <v-hover v-slot:default="{ hover }">
          <v-card
            :id="_key"
            class="mx-auto my-auto px-2 d-flex"
            width="352px"
            height="100px"
            :color="hover ? 'grey lighten-2' : 'grey lighten-3'"
            elevation="1"
            :to="item.link"
            :disabled="item.disabled"
          >
            <v-row
              no-gutters
              align="center"
              justify="center"
            >
              <v-col cols="2">
                <v-icon
                  class="my-auto d-flex justify-center"
                  :size="item.icon_size || 40"
                  color="primary"
                >
                  {{ item.icon }}
                </v-icon>
              </v-col>
              <v-col cols="10">
                <v-card-text class="mx-auto my-auto">
                  <div
                    class="subtitle-2"
                    style="word-break: normal"
                  >
                    {{ item.name }}
                  </div>
                  <div class="caption text--secondary">
                    {{ item.description }}
                  </div>
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import profilePermissionsMixin from '@/mixins/profilePermissionsMixin';

export default {
  name: 'Dados',
  mixins: [profilePermissionsMixin],
  data: () => ({
    categories: []
  }),
  mounted() {
    this.setCategories();
  },
  methods: {
    setCategories() {
      if (
        this.accessReleased('IMPORTACOES_DE_DADOS') ||
        this.accessReleased('CARGAS_CSV') ||
        this.accessReleased('IMPORTACOES_INDQUAL')
      ) {
        this.categories.push({
          name: 'Importações',
          itens: []
        });
      }

      if (this.accessReleased('IMPORTACOES_DE_DADOS')) {
        this.categories[0].itens.push({
          name: 'Importações de Dados',
          link: 'dados/importacoes-dados/historico',
          description: 'Importe dados diversos, como AIS e Laudos',
          icon: 'mdi-database-arrow-up-outline'
        });
      }

      if (this.accessReleased('CARGAS_CSV')) {
        this.categories[0].itens.push({
          name: 'Cargas de CSV',
          link: 'dados/cargas-csv/historico',
          description:
            'Carregue arquivos CSV para schemas do banco de dados do sistema',
          icon: 'mdi-file-upload-outline'
        });
      }

      if (this.accessReleased('IMPORTACOES_INDQUAL')) {
        this.categories[0].itens.push({
          name: 'Importações INDQUAL',
          link: 'dados/importacoes-xml/historico',
          description: 'Importe os arquivos XML com os indicadores do INDQUAL',
          icon: 'mdi-xml'
        });
      }

      if (
        this.accessReleased('VERSOES_DA_BDGD_HISTORICO') ||
        this.accessReleased('EXPURGOS_DE_BDGD_HISTORICO') ||
        this.accessReleased('RESTAURAR_BDGD_HISTORICO')
      ) {
        this.categories.push({
          name: 'Gestão da BDGD',
          itens: []
        });
      }

      if (this.accessReleased('VERSOES_DA_BDGD_HISTORICO')) {
        this.categories[1].itens.push({
          name: 'Versões da BDGD',
          link: 'dados/versoes-bdgds/historico',
          description: 'Visualize as versões ordinárias da BDGD',
          icon: 'mdi-file-compare'
        });
      }

      if (this.accessReleased('EXPURGOS_DE_BDGD_HISTORICO')) {
        this.categories[1].itens.push({
          name: 'Expurgos de BDGD',
          link: 'dados/expurgos-bdgd/historico',
          description: 'Exclua BDGD importada no sistema',
          icon: 'mdi-trash-can-outline'
        });
      }

      if (this.accessReleased('RESTAURAR_BDGD_HISTORICO')) {
        this.categories[1].itens.push({
          name: 'Restaurar BDGD',
          link: 'dados/restaurar-bdgd/historico',
          description: 'Restaure versões arquivadas da BDGD',
          icon: 'mdi-database-refresh-outline'
        });
      }
    }
  }
};
</script>
